/* eslint-disable */
import "./App.css";
import { useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome
} from "@fortawesome/free-solid-svg-icons";

function App() {
  const [starterProject, setStarterProject] = useState("");
  const [description, setDescription] = useState("");
  const [molecularProfile, setMolecularProfile] = useState("");
  const [flavor, setFlavor] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [ingredientFile, setIngredientFile] = useState(null);
  const [equipmentFile, setEquipmentFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [resultReceived, setResultReceived] = useState(false);
  const [status, setStatus] = useState("");
  const [chatInput, setChatInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [chatHistoryV2, setChatHistoryV2] = useState([]);
  const [shortName, setShortName] = useState("");
  const [listofpapers, setListofpapers] = useState("");
  const [references, setReferences] = useState("");
  const [agentFreedom, setAgentfreedom] = useState(0);
  const [revisions, setRevisions] = useState(2);
  const [modelRevisions, setModelRevisions] = useState(1);
  const inputRef = useRef(null);
  const [threadId, setThreadId] = useState(null);
  const [topic, setTopic] = useState(null);
  const [formData, setFormData] = useState({
    file: null,
    name: null
  });
  const fileInputRef = useRef(null);

  const [mode, setMOde] = useState({
    protocol: {
      creativeAssistance: false,
      DecisionSupport: false,
      StepByStep: false,
      newIP: false,
    },
    optimize: {
      cost: false,
      taste: false,
      texture: false,
      nutrition: false,
      sustainability: false,
      functionality: false,
      regulation: false,
    },
    analyse: {
      nutritionValue: false,
      carbonFootprint: false,
      ccompare: false,
      patent: false,
    },
    home: {
      default: true,
      generate: false,
      optimize: false
    }
  });
  const handleChange = (e) => {
    const { name, files } = e.target;
    if (files) setFormData({ file: files[0], name: name });
  };

  const handleHome = () => {
    setMOde(
      {
        protocol: {
          creativeAssistance: false,
          DecisionSupport: false,
          StepByStep: false,
          newIP: false,
        },
        optimize: {
          cost: false,
          taste: false,
          texture: false,
          nutrition: false,
          sustainability: false,
          functionality: false,
          regulation: false,
        },
        analyse: {
          nutritionValue: false,
          carbonFootprint: false,
          ccompare: false,
          patent: false,
        },
        home: {
          default: true,
          generate: false,
          optimize: false
        }
      }
    )
    setChatHistoryV2([]);
    setResultReceived(false);
    setThreadId(null);
    handleReset();
    setFormData({file: null, name: null});
  }

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setResult("");
    setChatHistory([]);
    setChatInput("");
    setShortName("");

    const queryParams = new URLSearchParams({
      gene_name: starterProject,
      disease_input: description,
      output_variable_input: molecularProfile,
      hypothesis_type: flavor,
      known_hypotheses_input: additionalInfo,
      tmp: agentFreedom,
      itr_a: revisions,
      itr_m: modelRevisions,
    }).toString();

    const endpoint = `https://algenius-api.northeurope.cloudapp.azure.com/api/v1/generate-hypotheses?${queryParams}`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
      });

      if (response.ok) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let resultData = "";

        // Stream the response in chunks
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setStatus("Complete");
            break;
          }
          if (value) {
            setShowResult(true);
          }
          const chunk = decoder.decode(value, { stream: true });
          const messages = chunk.trim().split("\n");
          messages.forEach((message) => {
            const parsedMessage = JSON.parse(message);
            if (parsedMessage.type === "AIMessage") {
              resultData = "";
              setLoading(false);
              resultData += parsedMessage.data;
              setResult(resultData);
              setStatus("Generating");
            }
            if (parsedMessage.type === "ToolMessage") {
              setStatus("Executing tools");
              setLoading(true);
            }
            if (parsedMessage.type === "HumanMessage") {
              setStatus("Revising");
              setLoading(true);
            }
            if (parsedMessage.type === "Name") {
              setShortName(parsedMessage.data);
              setListofpapers(parsedMessage.listofpapers);
              setReferences(parsedMessage.references);
            }
          });
        }
      } else {
        setResult("Error: Failed to generate protocols.");
      }
    } catch (error) {
      setResult("Error: Failed to generate protocols.");
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setShowResult(false);
    setStarterProject("");
    setDescription("");
    setMolecularProfile("");
    setFlavor("");
    setAdditionalInfo("");
    setIngredientFile(null);
    setEquipmentFile(null);
    setResult("");
    setListofpapers("");
    setReferences("");
    setRevisions(2);
    setModelRevisions(1);
    setChatHistory([]);
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, [chatInput])

  const handleChatSubmit = async () => {
    if (chatInput.trim() === "") return;

    setChatHistory((prevHistory) => [
      ...prevHistory,
      { sender: "User", text: chatInput },
    ]);

    const userMessage = chatInput;
    setChatInput("");

    setLoading(true);

    try {
      const response = await fetch("https://algenius-api.northeurope.cloudapp.azure.com/api/v1/chat", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          message: userMessage,
          protocol: result,
          short_name: shortName,
        }),
      });

      const data = await response.json();
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: "AI", text: data.reply },
      ]);
      setShowResult(true);
    } catch (error) {
      console.error("Error during chat submission:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleChat = async () => {
    setLoading(true)
    setChatHistoryV2((prevHistory) => [
      ...prevHistory,
      { sender: "User", text: chatInput },
    ]);
    const data = new FormData();
    data.append("data", JSON.stringify({
      query: chatInput,
      threadId: threadId,
      topic: topic
    }))
    if(formData.file){
      data.append("file", formData.file)
    }
    const response = await fetch("https://algenius-api.northeurope.cloudapp.azure.com/api/v2/ask", {
      method: "POST",
      body: data
    });
    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");

    let result = "";
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      result += decoder.decode(value, { stream: true });
      const lines = result.split("\n").filter(Boolean); // Remove empty lines
      for (const line of lines) {
        try {
          const json = JSON.parse(line); // Parse each JSON object
          if (json.type == "AIMessage") {
            setChatHistoryV2((prevHistory) => [
              ...prevHistory,
              { sender: "AI", text: json.data },
            ]);
            setThreadId(json.thread_id)
          }
        } catch (error) {
          console.error("JSON Parsing Error:", error, "Line:", line);
        }
        finally {
          setResultReceived(true);
          setChatInput("");
          setLoading(false);
          setFormData({file: null, name: null});
          if (fileInputRef.current) {
            fileInputRef.current.value = null; // Reset the file input field value
          }
        }
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleChat();
    }
  };

  const getUploadSection = () => {
    return (
      <div className="left-section">
        <div className="upload-section">
          <label className="upload-label">
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setIngredientFile)}
              className="upload-input"
            />
            <span className="upload-icon">+</span>
            <span className="upload-text">Upload Ingredient List</span>
          </label>
        </div>
        <div className="upload-section">
          <label className="upload-label">
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setEquipmentFile)}
              className="upload-input"
            />
            <span className="upload-icon">+</span>
            <span className="upload-text">Upload Equipment List</span>
          </label>
        </div>
      </div>
    );
  };

  const getDefaultView = () => {
    return (
      <>
        <div className="section-container">
          <div className="section-main">
            <div>Generate a new formulation</div>
            <div className="section-child-desc">
              Discover new flavors and create unique products with our formulation generator
            </div>
            <button onClick={() =>
              setMOde({
                ...mode,
                protocol: {
                  creativeAssistance: false,
                  DecisionSupport: false,
                  StepByStep: false,
                  newIP: false,
                },
                optimize: {
                  cost: false,
                  taste: false,
                  texture: false,
                  nutrition: false,
                  sustainability: false,
                  functionality: false,
                },
                analyse: {
                  nutritionValue: false,
                  carbonFootprint: false,
                  ccompare: false,
                  patent: false,
                },
                home: {
                  default: false,
                  generate: true
                }
              })
            }>Generate</button>
          </div>
          <div className="section-main">
            <div>Analyze and optimize a formulation</div>
            <div className="section-child-desc">
              Analyze nutritional value, carbon footprint or reglatory compliance. Improve and enhance your existing formulations for better results
            </div>
            <button onClick={() =>
              setMOde({
                ...mode,
                protocol: {
                  creativeAssistance: false,
                  DecisionSupport: false,
                  StepByStep: false,
                  newIP: false,
                },
                optimize: {
                  cost: false,
                  taste: false,
                  texture: false,
                  nutrition: false,
                  sustainability: false,
                  functionality: false,
                },
                analyse: {
                  nutritionValue: false,
                  carbonFootprint: false,
                  ccompare: false,
                  patent: false,
                },
                home: {
                  default: false,
                  generate: false,
                  optimize: true
                }
              })
            }>Analyze and optimize</button>
          </div>
        </div>
      </>
    )
  }

  const getCreativeAssistance = () => {
    return (
      <>
        <div>
          {!resultReceived && <div className="instruction">Not sure where to start ? Here are some conversation starters.</div>}
          <div className="section-container">
            {
              !resultReceived && <>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("What are the latest trends in beverage industry?");
                  }}>
                    What are the latest trends in beverage industry?
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("How can I make my plant-based burgers stand out in supermarkets of Mexico?")
                  }}>
                    How can I make my plant-based burgers stand out in supermarkets of Mexico?
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Help me brainstorm names for my high-protein sportsbar.")
                  }}>
                    Help me brainstorm names for my high-protein sportsbar.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("What is a novel idea for another jelly beans in supermarkets of Denmark?")

                  }}>
                    What is a novel idea for another jelly beans in supermarkets of Denmark?
                  </div>
                </div>
              </>
            }
          </div>
          {resultReceived && <div className="chat-container">
            <div className="chat-history">
              {chatHistoryV2.map((message, index) => (
                <div key={index} className="chat-message">
                  <strong>{message.sender}:</strong>
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </div>
              ))}
            </div>

          </div>}
          <div class="chat-input-container">
            <div class="chat-input-wrapper">
              <input
                type="file"
                name="file"
                id="fileInput"
                class="file-input"
                onChange={handleChange}
                ref={fileInputRef}
              />
              <label for="fileInput" class="file-label">📁</label>
              {formData?.file && <div><span>{formData?.file?.name}</span> <button onClick={()=>{
                setFormData({file:null, name:null})
                if (fileInputRef.current) {
                  fileInputRef.current.value = null; // Reset the file input field value
                }
                }}>x</button></div>}
              <input
                type="text"
                ref={inputRef}
                value={chatInput}
                placeholder="Message Algeniusfoods"
                class="chat-input"
                onKeyDown={handleKeyDown}
                onChange={(e) => setChatInput(e.target.value)}
              />
              <button class="send-button" onClick={handleChat}>➤</button>
            </div>

          </div>

        </div>
      </>
    );
  };

  const getOptimizeNutrition = () => {
    return (
      <>
        <div>
          {!resultReceived && <div className="instruction">Not sure where to start ? Here are some conversation starters.</div>}
          <div className="section-container">
            {
              !resultReceived && <>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("I want to upload my formulation to calculate the nutrition value.");
                  }}>
                    I want to upload my formulation to calculate the nutrition value.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Increase protein content of my formulation.")
                  }}>
                    Increase protein content of my formulation.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Get general guidance on nutritional value of my formulation.")
                  }}>
                    Get general guidance on nutritional value of my formulation.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("What health and nutritional claims can i state for my formulation.")
                  }}>
                    What health and nutritional claims can i state for my formulation.
                  </div>
                </div>
              </>
            }
          </div>
          {resultReceived && <div className="chat-container">
            <div className="chat-history">
              {chatHistoryV2.map((message, index) => (
                <div key={index} className="chat-message">
                  <strong>{message.sender}:</strong>
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </div>
              ))}
            </div>

          </div>}
          <div class="chat-input-container">
            <div class="chat-input-wrapper">
              <input
                type="file"
                id="fileInput"
                class="file-input"
                onChange={handleChange}
              />
              <label for="fileInput" class="file-label">📁</label>
              {formData?.file && <div><span>{formData?.file?.name}</span> <button onClick={()=>{
                setFormData({file:null, name:null})
                if (fileInputRef.current) {
                  fileInputRef.current.value = null; // Reset the file input field value
                }
                }}>x</button></div>}
              <input
                type="text"
                ref={inputRef}
                value={chatInput}
                placeholder="Message Algeniusfoods"
                class="chat-input"
                onKeyDown={handleKeyDown}
                onChange={(e) => setChatInput(e.target.value)}
              />
              <button class="send-button" onClick={handleChat}>➤</button>
            </div>
          </div>

        </div>
      </>
    );
  };
  const getOptimizeTaste = () => {
    return (
      <>
        <div>
          {!resultReceived && <div className="instruction">Not sure where to start ? Here are some conversation starters.</div>}
          <div className="section-container">
            {
              !resultReceived && <>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Help me imprve the taste of my formulation.");
                  }}>
                    Help me imprve the taste of my formulation.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Help me improve the texture of my formulation.")
                  }}>
                    Help me improve the texture of my formulation.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Help me improve functionality of my formulation.")
                  }}>
                    Help me improve functionality of my formulation.
                  </div>
                </div>
              </>
            }
          </div>
          {resultReceived && <div className="chat-container">
            <div className="chat-history">
              {chatHistoryV2.map((message, index) => (
                <div key={index} className="chat-message">
                  <strong>{message.sender}:</strong>
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </div>
              ))}
            </div>

          </div>}
          <div class="chat-input-container">
            <div class="chat-input-wrapper">
              <input
                type="file"
                id="fileInput"
                class="file-input"
                onChange={handleChange}
              />
              <label for="fileInput" class="file-label">📁</label>
              {formData?.file && <div><span>{formData?.file?.name}</span> <button onClick={()=>{
                setFormData({file:null, name:null})
                if (fileInputRef.current) {
                  fileInputRef.current.value = null; // Reset the file input field value
                }
                }}>x</button></div>}
              <input
                type="text"
                ref={inputRef}
                value={chatInput}
                placeholder="Message Algeniusfoods"
                class="chat-input"
                onKeyDown={handleKeyDown}
                onChange={(e) => setChatInput(e.target.value)}
              />
              <button class="send-button" onClick={handleChat}>➤</button>
            </div>
          </div>

        </div>
      </>
    );
  };
  const getOptimizeRegulation = () => {
    return (
      <>
        <div>
          {!resultReceived && <div className="instruction">Not sure where to start ? Here are some conversation starters.</div>}
          <div className="section-container">
            {
              !resultReceived && <>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Analyze an ingredient list for compilance with european food law.");
                  }}>
                    Analyze an ingredient list for compilance with european food law.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Analyze a product label for compilance with USA food law.")
                  }}>
                    Analyze a product label for compilance with USA food law.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Give me a list of possible claims for my product launch in europe.")
                  }}>
                    Give me a list of possible claims for my product launch in europe.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Analyze potential problems with product launch.")
                  }}>
                    Analyze potential problems with product launch.
                  </div>
                </div>
              </>
            }
          </div>
          {resultReceived && <div className="chat-container">
            <div className="chat-history">
              {chatHistoryV2.map((message, index) => (
                <div key={index} className="chat-message">
                  <strong>{message.sender}:</strong>
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </div>
              ))}
            </div>

          </div>}
          <div class="chat-input-container">
            <div class="chat-input-wrapper">
              <input
                type="file"
                id="fileInput"
                class="file-input"
                onChange={handleChange}
              />
              <label for="fileInput" class="file-label">📁</label>
              {formData?.file && <div><span>{formData?.file?.name}</span> <button onClick={()=>{
                setFormData({file:null, name:null})
                if (fileInputRef.current) {
                  fileInputRef.current.value = null; // Reset the file input field value
                }
                }}>x</button></div>}
              <input
                type="text"
                ref={inputRef}
                value={chatInput}
                placeholder="Message Algeniusfoods"
                class="chat-input"
                onKeyDown={handleKeyDown}
                onChange={(e) => setChatInput(e.target.value)}
              />
              <button class="send-button" onClick={handleChat}>➤</button>
            </div>
          </div>

        </div>
      </>
    );
  };

  const getOptimizeSustainability = () => {
    return (
      <>
        <div>
          {!resultReceived && <div className="instruction">Not sure where to start ? Here are some conversation starters.</div>}
          <div className="section-container">
            {
              !resultReceived && <>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Help me assess environmental impact of my formulation.");
                  }}>
                    Help me assess environmental impact of my formulation.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Calculate the carbon footprint.")
                  }}>
                    Calculate the carbon footprint.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Improve environmental impact.")
                  }}>
                    Improve environmental impact.
                  </div>
                </div>
                <div className="section-main">
                  <div className="section-child-desc" onClick={() => {
                    setChatInput("Analyze potential problems with product launch.")
                  }}>
                    Analyze potential problems with product launch.
                  </div>
                </div>
              </>
            }
          </div>
          {resultReceived && <div className="chat-container">
            <div className="chat-history">
              {chatHistoryV2.map((message, index) => (
                <div key={index} className="chat-message">
                  <strong>{message.sender}:</strong>
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </div>
              ))}
            </div>

          </div>}
          <div class="chat-input-container">
            <div class="chat-input-wrapper">
              <label htmlFor="fileInput" class="file-label">📁</label>
              {formData?.file && <div><span>{formData?.file?.name}</span> <button onClick={()=>{
                setFormData({file:null, name:null})
                if (fileInputRef.current) {
                  fileInputRef.current.value = null; // Reset the file input field value
                }
                }}>x</button></div>}

              <input
                type="file"
                id="fileInput"
                class="file-input"
                name="file"
                onChange={handleChange}
              />
              <input
                type="text"
                ref={inputRef}
                value={chatInput}
                placeholder="Message Algeniusfoods"
                class="chat-input"
                onKeyDown={handleKeyDown}
                onChange={(e) => setChatInput(e.target.value)}
              />
              <button class="send-button" onClick={handleChat}>➤</button>
            </div>
          </div>

        </div>
      </>
    );
  };
  const getGenerateView = () => {
    return (
      <>
        <div className="section-container">
          <div className="section-main">
            <div>Creative Assistance</div>
            <div className="section-child-desc">
              Need help brainstorming new product ideas? Want to know what is already out there on the market? Or just simply need an extra assistance sparking your creativity?
            </div>
            <button onClick={() => {
              setMOde({
                ...mode,
                protocol: {
                  creativeAssistance: true,
                  DecisionSupport: false,
                  StepByStep: false,
                  newIP: false,
                },
                optimize: {
                  cost: false,
                  taste: false,
                  texture: false,
                  nutrition: false,
                  sustainability: false,
                  functionality: false,
                },
                analyse: {
                  nutritionValue: false,
                  carbonFootprint: false,
                  ccompare: false,
                  patent: false,
                },
                home: {
                  default: false,
                  generate: false
                }
              })
              setTopic("creative-assistance")
            }
            }>creative assistance</button>
          </div>
          <div className="section-main">
            <div>Generate step-by-step formulation</div>
            <div className="section-child-desc">
              Do you already have detailed requirements for a new product? Use our generator to help you design a step by step formulation that you can test in the lab!
            </div>
            <button onClick={() =>
              setMOde({
                ...mode,
                protocol: {
                  creativeAssistance: false,
                  DecisionSupport: false,
                  StepByStep: true,
                  newIP: false,
                },
                optimize: {
                  cost: false,
                  taste: false,
                  texture: false,
                  nutrition: false,
                  sustainability: false,
                  functionality: false,
                },
                analyse: {
                  nutritionValue: false,
                  carbonFootprint: false,
                  ccompare: false,
                  patent: false,
                },
                home: {
                  default: false,
                  generate: false
                }
              })
            }>Generate step-by-step formulation</button>
          </div>
        </div>
      </>
    );
  };

  const getAnalyseandOptimizeView = () => {
    return (
      <>
        <div className="section-container">
          <div className="section-main">
            <div>Optimize nutrition</div>
            <div className="section-child-desc">
              Make sure your food product has excellent nutritional value
            </div>
            <button onClick={() => {
              setMOde({
                ...mode,
                protocol: {
                  creativeAssistance: false,
                  DecisionSupport: false,
                  StepByStep: false,
                  newIP: false,
                },
                optimize: {
                  cost: false,
                  taste: false,
                  texture: false,
                  nutrition: true,
                  sustainability: false,
                  functionality: false,
                },
                analyse: {
                  nutritionValue: false,
                  carbonFootprint: false,
                  ccompare: false,
                  patent: false,
                },
                home: {
                  default: false,
                  generate: false
                }
              })
              setTopic("nutrition");
            }}>Optimize nutrition</button>
          </div>
          <div className="section-main">
            <div>Optimize taste, texture, functionality</div>
            <div className="section-child-desc">
              Your formulation is almost ready, but not yet perfect? Let's optimize it for perfect taste, texture and functionality
            </div>
            <button onClick={() => {
              setMOde({
                ...mode,
                protocol: {
                  creativeAssistance: false,
                  DecisionSupport: false,
                  StepByStep: false,
                  newIP: false,
                },
                optimize: {
                  cost: false,
                  taste: true,
                  texture: false,
                  nutrition: false,
                  sustainability: false,
                  functionality: false,
                },
                analyse: {
                  nutritionValue: false,
                  carbonFootprint: false,
                  ccompare: false,
                  patent: false,
                },
                home: {
                  default: false,
                  generate: false
                }
              })
              setTopic("taste-texture-functionality");
            }}>Optimize taste, texture, functionality</button>
          </div>
          <div className="section-main">
            <div>Optimize for regulatory compliance</div>
            <div className="section-child-desc">
              Make sure your food product is compliant with legal regulations
            </div>
            <button onClick={() => {
              setMOde({
                ...mode,
                protocol: {
                  creativeAssistance: false,
                  DecisionSupport: false,
                  StepByStep: false,
                  newIP: false,
                },
                optimize: {
                  cost: false,
                  taste: false,
                  texture: false,
                  nutrition: false,
                  sustainability: false,
                  regulation: true,
                },
                analyse: {
                  nutritionValue: false,
                  carbonFootprint: false,
                  ccompare: false,
                  patent: false,
                },
                home: {
                  default: false,
                  generate: false
                }
              })
              setTopic("regulatory-compliance");
            }}>Optimize regulatory compliance</button>
          </div>
          <div className="section-main">
            <div>Optimize sustainability</div>
            <div className="section-child-desc">
              You have made a formulation that consumers will love! How about environmental impact? Lets tweak your formulation to optimize sustainability
            </div>
            <button onClick={() => {
              setMOde({
                ...mode,
                protocol: {
                  creativeAssistance: false,
                  DecisionSupport: false,
                  StepByStep: false,
                  newIP: false,
                },
                optimize: {
                  cost: false,
                  taste: false,
                  texture: false,
                  nutrition: false,
                  sustainability: true,
                  regulation: false,
                },
                analyse: {
                  nutritionValue: false,
                  carbonFootprint: false,
                  ccompare: false,
                  patent: false,
                },
                home: {
                  default: false,
                  generate: false
                }
              })
              setTopic("sustainability");
            }}>Optimize sustainability</button>
          </div>
        </div>
      </>
    );
  };

  const getDecisionSupport = () => {
    return (
      <>
        <div className="section-main">
          <div>Data driven decision</div>
          <div className="section-child-desc">
            Agent that can access market data and help make decisions
          </div>
        </div>
      </>
    );
  };

  const getDefaultProtocolGenerationForm = () => {
    return (
      <div className="right-section">
        <div className="input-field">
          <div style={{ padding: '5px' }}>Enter starter project</div>
          <input
            type="text"
            value={starterProject}
            onChange={(e) => setStarterProject(e.target.value)}
            placeholder="Eg: Plant-based burger."
          />
        </div>
        <div className="input-field">
          <div style={{ padding: '5px' }}>Enter description of the product</div>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Eg: Plant-based burger wth juicy texture."
          />
        </div>
        <div className="input-field">
          <div style={{ padding: '5px' }}>Enter the flavor to achieve</div>
          <input
            type="text"
            value={flavor}
            onChange={(e) => setFlavor(e.target.value)}
            placeholder="Eg: Deep savory taste"
          />
        </div>
        <div className="input-field">
          <div style={{ padding: '5px' }}>Additional Information</div>
          <input
            type="text"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            placeholder="Eg: No Artificial ingredients."
          />
        </div>
        <button
          onClick={handleSubmit}
          className="generate-button"
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate Protocols"}
        </button>
      </div>
    );
  };

  const getFileAndChat = () => {
    return (
      <>
        <div className="left-section">
          <div className="upload-section">
            <label className="upload-label">
              <input
                type="file"
                onChange={(e) => handleFileChange(e, setEquipmentFile)}
                className="upload-input"
              />
              <span className="upload-icon">+</span>
              <span className="upload-text">Upload protocol</span>
            </label>
          </div>
        </div>
        <div className="right-section">
          {/* <div className="chat-input-container"> */}
          <div className="input-field">
            <input
              type="text"
              value={result}
              onChange={(e) => setResult(e.target.value)}
              placeholder="Upload or paste your protocol"
              className="chat-input"
            />
          </div>
          <div className="input-field">
            <input
              type="text"
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              placeholder="Upload or paste your protocol"
              className="chat-input"
            />
          </div>

          <button onClick={handleChatSubmit} className="chat-submit-button">
            Send
          </button>
        </div>
        {/* </div> */}
      </>
    );
  };

  return (
    <div className="App">
      <div>
        <nav className="navbar">
          <div className="navbar-logo">
            <h1>Algenius Foods</h1>
          </div>
        </nav>
        <div className="home-row">
          <button onClick={handleHome}><FontAwesomeIcon icon={faHome} /></button>
        </div>
        {mode.home.default && <div className="welcome-container">
          <div className="welcome">
            <div><h1>Welcome to Algeniusfoods</h1></div>
            <div>
              Welcome to our prototype where you can design and improve food prodct formulations.
            </div>
            <button>Learn more about Algeniusfoods</button>
          </div>
        </div>
        }
        <header className="App-header">
          <div className={`overlay ${loading ? "active" : ""}`}>
            <div className="loading-spinner"></div>
          </div>
          {!showResult ? (
            <div className="container">
              {mode.home.default && getDefaultView()}
              {mode.home.generate && getGenerateView()}
              {mode.home.optimize && getAnalyseandOptimizeView()}
              {mode.protocol.StepByStep && getDefaultProtocolGenerationForm()}
              {mode.protocol.creativeAssistance && getCreativeAssistance()}
              {mode.protocol.DecisionSupport && getDecisionSupport()}
              {mode.protocol.newIP && getGenerateNewIP()}

              {mode.optimize.cost && getFileAndChat()}
              {mode.optimize.taste && getOptimizeTaste()}
              {mode.optimize.texture && getFileAndChat()}
              {mode.optimize.nutrition && getOptimizeNutrition()}
              {mode.optimize.sustainability && getOptimizeSustainability()}
              {mode.optimize.functionality && getFileAndChat()}
              {mode.optimize.regulation && getOptimizeRegulation()}
            </div>
          ) : (
            <>
              <div className="result-container">
                <div>Status: {status}</div>
                <div className="result">
                  <ReactMarkdown>{result}</ReactMarkdown>
                  {listofpapers && (
                    <>
                      {" "}
                      <h3>Private References</h3>
                      <ReactMarkdown>{listofpapers}</ReactMarkdown>
                    </>
                  )}
                  {references && (
                    <>
                      {" "}
                      <h3>Public References</h3>
                      <ReactMarkdown>{references}</ReactMarkdown>
                    </>
                  )}
                </div>
                {status === "Complete" && (
                  <div className="chat-container">
                    <div className="chat-history">
                      {chatHistory.map((message, index) => (
                        <div key={index} className="chat-message">
                          <strong>{message.sender}:</strong>
                          <ReactMarkdown>{message.text}</ReactMarkdown>
                        </div>
                      ))}
                    </div>
                    <div className="chat-input-container">
                      <input
                        type="text"
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        placeholder="Type your question here..."
                        className="chat-input"
                      />
                      <button
                        onClick={handleChatSubmit}
                        className="chat-submit-button"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <button onClick={handleReset} className="reset-button">
                Generate New Protocol
              </button>
            </>
          )}
        </header>
        <footer className="App-footer">
          <p>&copy; 2024 Algenius Foods. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
